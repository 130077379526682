@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.details {
  width: 100%;
  @include flex(center, center);
  @include direction(column);
  padding: 0 1rem;

  &__card {
    width: 18rem;
    padding: 0.5rem 1rem 1rem 1rem;
    @include flex(center, center);
    @include direction(column);
    background-color: $card-background-color;
    box-shadow: $box-shadow;
    border-radius: 50px;

    @include tablet {
      width: 45rem;
      padding: 1rem;
    }

    @include desktop {
      width: 50rem;
    }
  }

  &__wrap {
    width: 100%;
    padding: 0 0.5rem;
    @include direction(row);
    @include flex(space-between, center);
  }

  &__container {
    width: 100%;
  }

  &__picture {
    @include flex(center, center);
    position: relative;
  }

  &__image {
    width: 8rem;

    @include tablet {
      width: 14rem;
    }
  }

  &__middlebox {
    margin-bottom: 0.75rem;
  }

  &__title-box {
    @include flex(center, center);
    @include direction(column);
    width: 100%;
  }

  &__title {
    font-size: 1.6rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    text-align: center;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__wrapping {
    @include flex(space-between, center);
    padding: 0 0.5rem;
  }

  &__heading {
    font-size: 0.8rem;
    margin: 0.5rem 0;
    color: $white-background-color;
    font-weight: 500;

    &-main {
      @extend .details__heading;
      padding-left: 1.8rem;

      @include tablet {
        padding-right: 2rem;
      }
    }

    @include tablet {
      font-size: 0.8rem;
    }
  }

  &__header {
    text-align: center;
    margin: 0.5rem 0 0.25rem 0;
    font-size: 1.3rem;
    font-weight: 500;
  }

  &__headerbox {
    @include flex(space-between, center);
    margin-bottom: -0.5rem;
    padding: 0 0.5rem;
  }

  &__wrapper {
    padding-bottom: 0.75rem;
  }

  &__text {
    font-size: 0.9rem;
    margin: 0.5rem 0;

    @include tablet {
      font-size: 1rem;
    }

    &-quantity {
      text-align: right;
      font-size: 1rem;

      @include tablet {
        font-size: 1.1rem;
      }
    }

    &-med {
      margin: 0.5rem 0 0 0;
      font-size: 0.9rem;
      text-align: left;
      width: 5.5rem;
    }

    &-nomed {
      text-align: center;
    }

    &-name {
      @extend .details__text;
      width: 4rem;
      cursor: pointer;
      text-decoration: underline;

      @include tablet {
        width: 6rem;
      }
    }

    &-number {
      width: 1.35rem;
      text-align: right;
    }
  }

  &__buttons {
    @include buttons;
    padding: 2rem 0.5rem 0 0.5rem;
  }

  &__button {
    @include button-one;
    width: 9rem;
    font-size: 0.9rem;

    &--back {
      order: 1;
      @include button-one;

      @include tablet {
        order: 0;
      }
    }

    &-tablet {
      @include button-two;
      width: 10rem;
      font-size: 0.8rem;
    }
  }

  &__show-more {
    @include button-two;
    border-radius: 50px;
    border: none;
    color: $white-background-color;
    background-color: $background-color;
    height: 1.3rem;
    margin-top: 1rem;
  }

  &__showbox {
    @include flex(center, center);
  }

  &__times {
    @include direction(row);
    gap: 2rem;
  }

  &__box-age {
    width: 5.5rem;

    @include tablet {
      width: 5.6rem;
    }
  }

  &__loading {
    @include flex(center, center);
  }

  &__bigbox {
    width: 100%;

    @include tablet {
      @include direction(row);
      gap: 1rem;
    }
  }

  &__box-log {
    padding-top: 0.5rem;
    margin-top: 1rem;
    border-top: $border;

    @include tablet {
      border-left: $border;
      border-top: none;
      padding-left: 1rem;
      padding-top: 0;
      margin-top: 0;
      width: 100%;
    }
  }

  &__buttonbox {
    @include flex(center, center);
    margin-top: 0.5rem;

    @include tablet {
      display: none;
    }
  }

  &__topbox {
    @include tablet {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &__tabletbuttons {
    display: none;

    @include tablet {
      width: 100%;
      @include flex(center, center);
      gap: 16rem;
    }
  }
}
