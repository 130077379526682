$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

$background-color: #25383c;
$white-background-color: #fffefa;
$card-background-color: #93b1a7;
$card-color: #3A574E;
$main-font-color: #1B322B;
$button-font-color: #ffffff;
$invalid-font-color: #a9b7c0;
$red-color: #9d0208;

$border: 1px solid $card-color;

$box-shadow: 4px 4px 0 0 $card-color;
$box-shadow-button: 0 8px 12px rgba(0, 0, 0, 0.2);

$box-shadow-medication:rgba(0, 0, 0, 0.1) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.09) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px, rgba(0, 0, 0, 0.1) 0px -5px 10px 0px;


$box-shadow-medication-hover: rgba(0, 0, 0, 0.1) 0px -23px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -36px 35px 0px inset,
    rgba(0, 0, 0, 0.09) 0px -79px 45px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.12) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.1) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px, rgba(0, 0, 0, 0.1) 0px -5px 20px 0px;
