@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.medication {
  width: 100%;
  @include flex(center, center);
  @include direction(column);

  @include tablet {
    margin: 0;
  }

  &__card {
    width: 18rem;
    padding: 1rem;
    @include flex(center, center);
    @include direction(column);
    background-color: $card-background-color;
    box-shadow: $box-shadow;
    border-radius: 50px;

    @include tablet {
      width: 40rem;
    }
  }

  &__wrap {
    @include direction(column);
  }

  &__container {
    width: 100%;

    @include tablet {
      @include direction(row);
      gap: 2.5rem;
      margin-top: 1rem;
    }
  }

  &__picture {
    @include flex(center, center);
    position: relative;
    cursor: pointer;
  }

  &__image {
    width: 12rem;

    @include tablet {
      width: 14rem;
    }
  }

  &__bubble {
    width: 4rem;
    position: absolute;
    right: 10rem;
    bottom: 7rem;
    animation: vapor 1s ease-in;

    @include tablet {
      right: 21.5rem;
      bottom: 8rem;
    }
  }

  @keyframes vapor {
    0% {
      opacity: 0;
      transform: translateY(20px);
      filter: blur(4px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      filter: blur(0);
    }
  }

  &__id {
    position: absolute;
    right: 11.5rem;
    bottom: 8.25rem;
    font-size: 0.7rem;
    animation: vapor 1s ease-in;

    @include tablet {
      right: 23rem;
      bottom: 9.3rem;
    }
  }

  &__middlebox {
    margin-bottom: 0.75rem;
  }

  &__title-box {
    @include flex(center, center);
    @include direction(column);
    width: 100%;
    border-bottom: 1px solid $card-color;

    @include tablet {
      border: none;
    }
  }

  &__title {
    font-size: 1.6rem;
    margin: 0.5rem 0;
    font-weight: 500;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__wrapping {
    @include flex(space-between, center);
    gap: 6rem;
  }

  &__heading {
    font-size: 0.7rem;
    margin: 0.5rem 0;
    font-weight: 500;
    color: $white-background-color;

    &--schedule {
      margin-bottom: 0;
    }

    @include tablet {
      font-size: 0.8rem;
    }
  }

  &__header {
    text-align: center;
    margin: 1rem 0 0 0;
    font-size: 1.3rem;
    font-weight: 500;

    @include tablet {
      margin: 0 0 0.5rem 0;
    }
  }

  &__headerbox {
    @include flex(space-between, center);
    margin-bottom: -0.5rem;
  }

  &__text {
    font-size: 0.9rem;
    margin: 0.5rem 0;

    @include tablet {
      font-size: 1rem;
    }

    &-quantity {
      text-align: right;
      font-size: 1rem;

      @include tablet {
        font-size: 1rem;
      }
    }

    &-history {
      @extend .medication__text;
      font-size: 0.9rem;
      margin: 0.5rem 0 0 0;
      @include tablet {
        font-size: 1rem;
      }
    }
  }

  &__buttons {
    padding: 2rem 0.5rem 0 0.5rem;
    gap: 1rem;
    width: 100%;
    @include flex(center, center);
    @include direction(column);

    @include tablet {
      @include direction(row);
    }
  }

  &__button {
    @include button-one;

    &-back {
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__show-button {
    border: none;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 0.8rem;
  }

  &__showbox {
    @include flex(center, center);
  }

  &__times {
    @include direction(row);
    gap: 2rem;
  }

  &__box-log {
    @include tablet {
      border-left: $border;
      padding-left: 2rem;
    }
  }

  &__loading {
    @include flex(center, center);
  }

  &__maintitle {
    font-weight: 500;
    margin-top: 0.5rem;
    font-size: 1.6rem;
    text-align: center;
  }

  &__h4 {
    font-weight: 500;
  }
}
