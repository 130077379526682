@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.delete {
  margin-top: 2rem;
  background-color: rgba($red-color, 0.5);
  border-radius: 50px;
  @include flex(center, center);
  @include direction(column);

  @include tablet {
    width: 20rem;
  }

  &__title {
    margin: 1.5rem 0 0 0;
  }

  &__subtitle {
    margin: 1rem 0;
    font-size: 0.8rem;
  }

  &__select {
    @include flex(space-between, center);
    gap: 2rem;
    margin-bottom: 0.5rem;
  }

  &__option {
    border: none;
    border-radius: 50px;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &--yes {
      color: white;
      background-color: $red-color;
    }

    &--no {
      color: white;
      background-color: $card-color;
    }
  }
}
