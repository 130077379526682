@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.spinner {
  @include flex(center, center);

  &__wheel {
    width: 2rem;
    border-radius: 50%;
    aspect-ratio: 1;
    background: radial-gradient(farthest-side, $card-color, 80%, #0000) top/6px 6px
        no-repeat,
      conic-gradient(#0000 35%, $card-color);
    animation: spin 1s infinite linear;

    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 6px),
      #000 0
    );
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
