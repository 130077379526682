@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.time{
    @include direction(row);
    @include flex(center, center);
    gap: 0.3rem;
    cursor: none;


    &__icon{
        width: 1.2rem;
        height: 1.2rem;
    }
}
