@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.edit-med {
  @include flex(center, center);
  margin: 0 1rem;

  &__wrap {
    margin: 1rem;
    width: 18rem;
    @include flex(center, center);
    @include direction(column);
  }

  &__title {
    @include tablet {
      font-size: 2rem;
    }
  }

  &__header {
    position: relative;
  }

  &__arrow {
    width: 1.3rem;
    position: absolute;
    top: 1.5rem;
    left: -2rem;
    cursor: pointer;

    @include tablet {
      top: 2.2rem;
    }
  }

  &__times {
    @include direction(row);
    @include flex(start, baseline);
    flex-wrap: wrap;
    width: 100%;

    @include tablet {
      @include direction(column);
      flex-wrap: nowrap;
    }
  }

  &__form {
    @include direction(column);
    width: 100%;

    @include tablet {
      @include flex(center, center);
    }
  }

  &__time {
    width: 100%;
    @include flex(start, start);
    @include direction(column);
    gap: 0.1rem;
  }

  &__input {
    @include input;
    margin-bottom: 1rem;
    width: 100%;

    &-time {
      @include arrow-input;
    }

    &-error {
      border: 1.5px solid $red-color;
    }
  }

  &__schedule {
    @extend .add-med__input;
    @include arrow-input;
  }

  &__button {
    @include button-one;
    margin-top: 1rem;

    &--back {
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__label {
    margin-bottom: 0.25rem;
    font-size: 0.85rem;

    @include tablet {
      @include flex(start, center);
      align-self: start;
    }
  }

  &__buttons {
    @include flex(center, center);
    @include direction(column);

    @include tablet {
      @include direction(row);
      gap: 1rem;
    }
  }

  &__error {
    @include error;
  }
}

.add-med {
  @include flex(center, center);
  margin: 0 1rem;

  &__wrap {
    margin: 1rem;
    width: 18rem;
    @include flex(center, center);
    @include direction(column);
  }

  &__title {
    @include tablet {
      font-size: 2rem;
    }
  }

  &__header {
    position: relative;
  }

  &__arrow {
    width: 1.3rem;
    position: absolute;
    top: 1.5rem;
    left: -2rem;
    cursor: pointer;

    @include tablet {
      top: 2.2rem;
    }
  }

  &__times {
    @include direction(row);
    @include flex(start, baseline);
    flex-wrap: wrap;
    width: 100%;

    @include tablet {
      @include direction(column);
      flex-wrap: nowrap;
    }
  }

  &__form {
    @include direction(column);
    width: 100%;

    @include tablet {
      @include flex(center, center);
    }
  }

  &__time {
    width: 100%;
    @include flex(start, start);
    @include direction(column);
    gap: 0.1rem;
  }

  &__input {
    @include input;
    margin-bottom: 1rem;
    width: 100%;

    &-time {
      @include arrow-input;
    }

    &-error {
      border: 1.5px solid $red-color;
    }
  }

  &__schedule {
    @extend .add-med__input;
    @include arrow-input;
  }

  &__button {
    @include button-one;
    margin-top: 1rem;

    &--back {
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__label {
    margin-bottom: 0.25rem;
    font-size: 0.85rem;

    @include tablet {
      @include flex(start, center);
      align-self: start;
    }
  }

  &__buttons {
    @include flex(center, center);
    @include direction(column);

    @include tablet {
      @include direction(row);
      gap: 1rem;
    }
  }

  &__error {
    @include error;
  }
}
