@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.home {
  @include flex(center, center);
  @include direction(column);

  &__container {
    @include direction(column);
    @include flex(center, center);
    width: 100%;
    margin-bottom: 2rem;

    @include tablet {
      padding: 0 1rem;
    }
    @include desktop {
      width: 63.75rem;
    }
  }

  &__title {
    font-weight: 600;

    @include tablet {
      font-size: 3rem;
      margin: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  &__patientlist {
    width: 100%;
    padding: 0 1rem;
    @include flex(center, center);
    @include direction(column);
    gap: 3rem;
    margin-bottom: 2rem;

    @include tablet {
      @include direction(column);
      gap: 4rem;
      padding: 0;
    }
  }

  &__button {
    @include button-one;
  }

  &__nopatients {
    @include flex(center, center);
    @include direction(column);
    height: 10rem;
    gap: 1.75rem;
  }

  &__subtitle {
    font-size: 1.5rem;
    text-align: center;

    @include tablet {
      font-size: 1.6rem;
    }
  }
}
