@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.user {
  border-radius: 50px;
  background-color: $card-background-color;
  box-shadow: $box-shadow;
  width: 14rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 0 0 $card-color;
  }

  &__title {
    text-align: center;
    margin: 1.5rem 0;
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__container {
    @include flex(center, center);
    @include direction(column);
  }

  &__wrap {
    @include direction(row);
    @include flex(space-between, end);
    width: 100%;
    margin: 0.5rem 0;
  }

  &__label {
    font-size: 0.7rem;
    margin: 0;
    color: $white-background-color;
  }

  &__text {
    margin: 0.5rem 0;
  }

  &__birth {
    @include flex(space-between, center);
    padding: 0 1rem;
    width: 100%;
  }

  &__info {
    @include flex(space-between, center);
    @include direction(row);
    padding: 0 1rem;
    width: 100%;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  &__button {
    cursor: pointer;
    background-image: url(../../assets/icons/edit.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__details {
    cursor: pointer;
    margin: 0;
    font-size: 0.65rem;
    text-decoration: underline;
  }

  &__link {
    margin: 0.5rem 0;
  }
}
