@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.login {
    padding-top: 3rem;
    padding-bottom: 6rem;

  &__wrap {
    padding: 1rem 0;
    @include flex(center, center);
    @include direction(column);
    gap: 1rem;

    @include tablet {
      @include direction(row);
      gap: 0;
    }
  }

  &__logo {
    width: 10rem;
  }

  &__title {
    font-size: 3.5rem;
    margin: 0.5rem;
  }

  &__form-container {
    padding: 1rem 2rem 5rem 2rem;
    width: 100%;

    @include tablet {
      padding: 1rem 6rem;
      @include flex(center, center);
      @include direction(column);
    }
  }

  &__heading {
    @include flex(center, center);
    gap: 1.5rem;
    padding-bottom: 0.5rem;
  }

  &__subtitle {
    margin: 0.5rem 0;
    font-weight: 800;
    font-size: 1.5rem;
    text-decoration: underline;
    cursor: pointer;

    &--inactive {
      @extend .login__subtitle;
      font-weight: 400;
      text-decoration: none;
    }
  }

  &__form {
    @include flex(center, center);
    @include direction(column);
    gap: 1rem;
  }

  &__form-box {
    @include direction(column);
    width: 100%;
    gap: 0.25rem;
  }

  &__label {
    font-size: 0.85rem;
  }

  &__input {
    @include input;
    background-color: transparent;

    &-select{
      @include arrow-input;
    }
  }

  &__button {
    @include button-one;
    width: 100%;
    font-size: 1rem;
    margin-top: 1rem;
  }

  &__success {
    @include flex (center, center);
    font-weight: 500;
  }

  &__error {
   color: $red-color;
  }
}
