@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.edit {
  &__wrap {
    @include flex(center, center);
    @include direction(column);
    padding: 0.5rem;
  }

  &__title {
    font-size: 1.5rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__header {
    position: relative;
  }

  &__arrow {
    width: 1.3rem;
    position: absolute;
    top: 1.5rem;
    left: -2rem;
    cursor: pointer;

    @include tablet {
      top: 2.2rem;
    }
  }

  &__form {
    @include direction(column);
    width: 18rem;

    @include tablet {
      @include flex(center, center);
    }
  }

  &__input {
    @include input;
    margin-bottom: 1rem;
    width: 100%;

    &-error {
      border: 1px solid $red-color;
    }
  }

  &__date {
    position: relative;
    @extend .edit__input;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 6rem;
    }
  }

  &__buttons {
    @include flex(center, center);
    @include direction(column);

    gap: 1rem;
    margin-top: 1rem;

    @include tablet {
      @include direction(row);
    }
  }

  &__button {
    @include button-one;

    &--delete {
      background-color: $red-color;
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__box {
    @include direction(column);
  }

  &__error {
    @include error;
  }
}

.add {
  &__wrap {
    @include flex(center, center);
    @include direction(column);
    padding: 0.5rem;
  }

  &__title {
    font-size: 1.5rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__header {
    position: relative;
  }

  &__arrow {
    width: 1.3rem;
    position: absolute;
    top: 1.5rem;
    left: -2rem;
    cursor: pointer;

    @include tablet {
      top: 2.2rem;
    }
  }

  &__form {
    @include direction(column);
    width: 18rem;

    @include tablet {
      @include flex(center, center);
    }
  }

  &__input {
    @include input;
    margin-bottom: 1rem;
    width: 100%;

    &-error {
      border: 1px solid $red-color;
    }
  }

  &__date {
    position: relative;
    @extend .edit__input;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 6rem;
    }
    
    &-error {
      border: 1px solid $red-color;
    }
  }

  &__buttons {
    @include flex(center, center);
    @include direction(column);

    gap: 1rem;
    margin-top: 1rem;

    @include tablet {
      @include direction(row);
    }
  }

  &__button {
    @include button-one;

    &--delete {
      background-color: $red-color;
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__box {
    @include direction(column);
  }

   &__error {
    @include error;
  }
}
