@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.user-info {
  margin: 2rem 1rem 5rem 1rem;

  @include tablet {
    margin: 0 2rem;
  }

  @include desktop {
    @include flex(center, center);
    @include direction(column);
    padding: 0rem 8rem;
  }

  &__form {
    width: 100%;
    margin-bottom: 10rem;

    @include tablet {
      @include flex(center, center);
      @include direction(column);
    }
  }

  &__container {
    @include flex(center, center);
    @include direction(column);
    width: 100%;
    margin-top: 3rem;
  }

  &__wrapper {
    @include direction(column);
    @include flex(center, center);
    gap: 1rem;
  }

  &__box {
    @include direction(row);
    width: 100%;
    gap: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 500;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__label {
    margin: 0;
    font-size: 0.85rem;
  }

  &__text {
    margin: 0;
    font-size: 0.85rem;
    font-style: italic;

    &-invisible {
      @extend .user-info__text;
      filter: blur(2.5px);
    }

    &-visible {
      @extend .user-info__text;
    }
  }

  &__box {
    margin-bottom: 1rem;
  }

  &__button {
    @include button-one;

    &-cancel {
      order: 2;

      @include tablet {
        order: 0;
      }
    }
  }

  &__visible {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.3rem;
    background: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    background-image: url(../../assets/icons/show.png);

    @include tablet {
      margin-bottom: 0.7rem;
    }
  }

  &__invisible {
    @extend .user-info__visible;
    margin-top: 0.5rem;
    background-image: url(../../assets/icons/noshow.png);

  }

  &__details {
    @include direction(row);
    gap: 1rem;
  }

  &__buttons {
    @include direction(column);
    @include flex(center, center);
    gap: 1rem;
    margin-top: 2rem;

    @include tablet {
      @include direction(row);
      margin-top: 1rem;
    }
  }

  &__group {
    @include direction(column);
    margin-bottom: 1rem;
  }

  &__input {
    @include input;

    &::placeholder {
      font-style: italic;
    }
    &-select{
      @include arrow-input;
    }
  }

  &__visibilitybox {
    @include direction(row);
    @include flex(center, center);
    gap: 0.5rem;
  }

  &__alert {
    text-align: center;
  }
}
