@use "./variables" as *;

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin flex($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin direction($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin button-one {
  cursor: pointer;
  width: 18rem;
  height: 2rem;
  border: none;
  border-radius: 50px;
  background-color: $card-color;
  color: $button-font-color;
  font-size: 1rem;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease;

  &--delete {
    background-color: $red-color;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: $box-shadow-button;
  }

  @include tablet {
    width: 13.5rem;
  }
}

@mixin button-two {
  @include button-one;
  font-size: 0.7rem;
  width: 4.5rem;

  @include tablet {
    width: 6rem;
    font-size: 0.8rem;
  }
}

@mixin input {
  border: $border;
  border-radius: 50px;
  height: 2rem;
  background-color: transparent;
  padding-left: 0.75rem;

  @include tablet {
    width: 28rem;
  }
}

@mixin buttons {
  @include flex(center, center);
  @include direction(column);
  gap: 1rem;

  @include tablet {
    @include direction(row);
  }
}

@mixin hover-text {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

@mixin arrow-input {
  background-image: url(../../assets/icons/down-arrow.png);
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  appearance: none;
}

@mixin error {
  font-size: 0.8rem;
  color: $red-color;
  margin-top: -1rem;
  text-align: right;
  padding-bottom: 0.5rem;
}
