@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Urbanist";
  font-weight: 400;
  color: $main-font-color;
  background-color:#9fdfbb;
  background-image: url("/assets/images/pattern.png");
  background-repeat: repeat;
  background-size: 12rem 12rem;
  background-position: top center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Urbanist";
  color: $main-font-color;
}

a {
  text-decoration: none;
  color: $main-font-color;
}

body {
  min-height: 100vh;
}

.route {
  min-height: 100vh;
}
