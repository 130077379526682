@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.card {
  width: 15.625rem;
  height: 15.625rem;
  background-color: $card-background-color;
  border-radius: 7.5rem;
  box-shadow: $box-shadow-medication;
  padding: 1rem 1rem 0.5rem 1rem;
  @include flex(Center, center);
  @include direction(column);
  gap: 1.5rem;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: $box-shadow-medication-hover;
  }

  &__top {
    @include flex(center, center);
    @include direction(column);
    gap: 0.5rem;
  }

  &__title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }

  &__schedule {
    @include flex(center, center);
    gap: 2rem;
  }

  &__timebox {
    @include direction(column);
    @include flex(center, center);
    gap: 0.5rem;
  }

  &__time {
    margin: 0;
  }

  &__compliance {
    margin: 0;
    font-weight: 800;

    &--totake {
      font-weight: 500;
    }
  }

  &__button {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-image: url(../../assets/images/taken.png);
    background-size: 2.6rem;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;

    &--totake {
      @extend .card__button;
      background-image: url(../../assets/images/totake.png);
      transition: 1s ease;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__expand {
    font-size: 0.65rem;
    text-decoration: underline;
  }
}
