@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
  @include flex(center, center);
  padding-top: 0.5rem;

  &__container {
    @include direction(column);
    width: 100%;
    gap: 0.5rem;
    margin: 0 0.5rem;

    @include tablet {
      @include direction(row);
      @include flex(space-between, center);
      padding: 0 2rem;
    }
    @include desktop {
      width: 63.75rem;
    }
  }

  &__wrap {
    @include flex(center, center);
    @include direction(row);
    gap: 1rem;
  }

  &__logo {
    width: 5rem;
  }

  &__title {
    font-size: 2rem;
    color: $main-font-color;
    font-weight: 600;
  }

  &__nav {
    display: none;

    @include tablet {
      cursor: pointer;
      width: auto;
      @include flex(space-between, center);
      gap: 2rem;
    }
  }

  &__navlink {
    @include hover-text;

    &--active{
      // text-decoration: underline;
      font-weight: 700;
    }
  }

  &__image {
    width: 1.5rem;
  }
}
