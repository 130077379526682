@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.nav {
  @include direction(row);
  @include flex(space-between, center);
  padding: 0.5rem 2rem;
  z-index: 1;
  background-color: $white-background-color;
  background-repeat: repeat;
  background-size: 12rem 12rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1.5px solid $card-color;

  @include tablet {
    display: none;
  }

  &__image {
    width: 2rem;
    cursor: pointer;
  }

  &__box{
    @include flex(center, center);
    @include direction(column)
  }

  &__text{
    font-size: 0.8rem;
    margin: 0;
  }
}
