@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.profile {
  margin: 2rem 1rem 5rem 1rem;

  @include tablet {
    margin: 0 2rem;
  }

  @include desktop {
    @include flex(center, center);
    @include direction(column);
    padding: 0rem 8rem;
  }

  &__wrap {
    @include desktop {
      width: 63.75rem;
    }
  }

  &__container {
    display: none;

    @include tablet {
      @include flex(end, center);
      @include direction(row);
      gap: 1rem;
      margin: 0 0.5rem;
    }

    @include desktop {
      margin: 0 2rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__heading {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 500;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__picture {
    width: 5rem;
    transition: transform 0.5s ease-in-out;

    &:hover {
      animation: spin 3s infinite linear;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @include tablet {
      order: 1;
    }
  }

  &__list {
    margin-bottom: 2rem;
  }

  &__patients {
    @include direction(column);
    @include flex(center, center);
    gap: 1rem;
    width: 100%;

    @include tablet {
      flex-wrap: wrap;
      @include direction(row);
      @include flex(center, center);
    }

    @include desktop {
      gap: 3rem;
    }
  }

  &__settings {
    @include direction(column);
  }

  &__box {
    @include direction(row);
    @include flex(start, center);
    gap: 1rem;
  }

  &__label {
    margin: 0;
    font-size: 1rem;

    &-italic {
      font-style: italic;
    }
  }

  &__email {
    font-size: 1.1rem;
    text-decoration: underline;
  }

  &__buttons {
    @include direction(column);
    @include flex(center, center);
    gap: 1rem;
    margin-top: 1.5rem;

    @include tablet {
      @include direction(row);
      @include flex(center, center);
      gap: 1rem;
    }
  }

  &__action {
    @include button-one;

    &-back {
      @include button-one;
      order: 1;

      @include tablet {
        order: 0;
      }
    }
  }

  &__nousers {
    @include flex(center, center);
  }
}
