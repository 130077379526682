@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.footer {
  margin-top: 1rem;
  @include flex(center, center);
  @include direction(column);
  width: 100%;
  margin-bottom: 3.6rem;

  @include tablet {
    margin-bottom: 0;
  }

  &__wrap {
    display: none;
    @include tablet {
      @include direction(column);
      @include flex(center, center);
      margin-top: 1rem;
      padding: 0 1rem;
      width: 20rem;
      position: relative;
    }
  }

  &__container {
    @include flex(space-between, baseline);
    gap: 1rem;
    width: 100%;
    padding: 0rem 1rem;
  }

  &__iconbox {
    @include flex(space-between, center);
  }

  &__imagebox {
    @include flex(center, center);
    position: relative;
  }

  &__title {
    margin: 0.5rem 0;
    font-size: 1.1rem;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__text {
    margin: 0.5rem 0;
    font-size: 0.8rem;
    font-weight: 200;

    @include tablet {
      font-size: 0.9rem;
    }
  }

  &__legal {
    font-size: 0.7rem;
    margin: 0;
  }

  &__copyright {
    @include flex(center, center);
    padding: 0.5rem 0;
  }

  &__image {
    display: none;
    @include tablet {
      width: 3.5rem;
      position: absolute;
      right: -15rem;
      bottom: 1rem;
      display: inline;
    }
  }

  &__pill {
    display: none;
    @include tablet {
      display: block;
      position: absolute;
      width: 3.3rem;
      left: -5rem;
      top: 2.2rem;
    }
  }

  &__icon {
    width: 1.55rem;

    @include tablet {
      width: 2rem;
    }
  }
}
